import { createTheme } from "@mui/material/styles";
import type {} from "@mui/x-data-grid/themeAugmentation";

import SfProDisplayRegular from "../assets/fonts/SF_Pro_Display/SFProDisplay-Regular.ttf";
import SfProDisplaySemiBold from "../assets/fonts/SF_Pro_Display/SFProDisplay-Semibold.ttf";

declare module "@mui/material/styles/createPalette" {
  interface CommonColors {
    mainBg: string;
    mainShadow: string;
    partyTime: string;
    bulmaHair: string;
    heirloomHydrangea: string;
    bellflowerBlue: string;
    gramsHair: string;
    wePeep: string;
    featherSoftBlue: string;
    deutziaWhite: string;
    wolfram: string;
    doverGrey: string;
    border: string;
    gray3: string;
    gray4: string;
    gray5: string;
    gray6: string;
    gray7: string;
    emailSidebar: string;
    hintText: string;
    mentionsBg: string;
    mentionText: string;
  }
}

// Custom colors. Names generated by https://colornamer.robertcooper.me/
const MAIN_SHADOW = "0px 20px 25px 0px #4C67641A";
const BLACK = "#000";
const WHITE = "#fff";

//red
const PARTY_TIME = "#CC2630";
const BULMA_HAIR = "#38a169";
const WE_PEEP = "#fed7d7";

//blue
const HEIRLOOM_HYDRANGEA = "#3182CE";

const BELLFLOWER_BLUE = "#e2e8f0";
const GRAMS_HAIR = "#f4f5f7";
const FEATHER_SOFT_BLUE = "#A0AEC0";
const DEUTZIA_WHITE = "#F7FAFC";
const WOLFRAM = "#b6b6b6";
const DOVER_GREY = "#848484";

const BORDER = "1px solid #E2E8F0";
const GRAY_3 = "#CBD5E0";
const GRAY_4 = "#A0AEC0";
const GRAY_5 = "#718096";
const GRAY_6 = "#4A5568";
const GRAY_7 = "#2D3748";

const SIDEBAR_EMAIL = "#0000007A";
const HINT_TEXT = "#0000004D";

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      '"Poppins"',
      '"SF-Pro-Display"',
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: { main: PARTY_TIME },

    common: {
      mainBg: GRAMS_HAIR,
      mainShadow: MAIN_SHADOW,
      black: BLACK,
      white: WHITE,
      partyTime: PARTY_TIME,
      heirloomHydrangea: HEIRLOOM_HYDRANGEA,
      bulmaHair: BULMA_HAIR,
      bellflowerBlue: BELLFLOWER_BLUE,
      gramsHair: GRAMS_HAIR,
      wePeep: WE_PEEP,
      featherSoftBlue: FEATHER_SOFT_BLUE,
      deutziaWhite: DEUTZIA_WHITE,
      wolfram: WOLFRAM,
      doverGrey: DOVER_GREY,
      border: BORDER,
      gray3: GRAY_3,
      gray4: GRAY_4,
      gray5: GRAY_5,
      gray6: GRAY_6,
      gray7: GRAY_7,
      emailSidebar: SIDEBAR_EMAIL,
      hintText: HINT_TEXT,
      mentionsBg: "rgb(235, 233, 160)",
      mentionText: "#6ea0f0",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 700,
          textTransform: "capitalize",
        },
        contained: {
          backgroundColor: "black",
          "&:hover": {
            backgroundColor: "#677280",
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: GRAY_7,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: GRAY_3,
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: PARTY_TIME,
            },
          },
          "&:hover": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: WOLFRAM,
            },
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 16px 24px",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          " .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus-within":
            {
              outline: "none",
            },
        },
      },
    },
    MuiCssBaseline: {
      // styleOverrides: `
      //   @font-face {
      //     font-family: 'SF-Pro-Display';
      //     src: url("/fonts/SF_Pro_Display/SFProDisplay-Bold.otf") format('ttf');
      //     unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      //   }
      // `
      styleOverrides: {
        "@font-face": [
          {
            fontFamily: '"SF-Pro-Display"',
            fontWeight: 600,
            src: `
              local('SF-Pro-Display'),
              url(${SfProDisplaySemiBold})
            `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
          {
            fontFamily: '"SF-Pro-Display"',
            fontWeight: 400,
            src: `
              local('SF-Pro-Display-Regular'),
              url(${SfProDisplayRegular})
            `,
            unicodeRange:
              "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
          },
        ],
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1536,
      xxl: 1920,
      mobile: 320,
      tablet: 568,
      desktop: 1025,
    },
  },
});

export default theme;
