import { PublicPaths } from "./path/PublicPath";
import { IRoute, IziRoute } from "@utils/IziRoute";
import loadable from "@loadable/component";
import { Navigate } from "react-router-dom";

const GatherlanceLanding = loadable(() => import("@pages/gatherlance-landing"));
const Terms = loadable(() => import("@pages/policy/Terms"));
const Privacy = loadable(() => import("@pages/policy/Privacy"));
const GatherlanceLandingFreelanceView = loadable(
  () => import("@pages/gatherlance-extra-landing/pages/FreelanceView")
);
const GatherlanceLandingEmployeeView = loadable(
  () => import("@pages/gatherlance-extra-landing/pages/EmployeeView")
);

const TITLE = "Gatherlance";

export const routes: IRoute[] = [
  {
    id: PublicPaths.indexPage,
    path: PublicPaths.indexPage,
    title: TITLE,
    index: false,
    private: false,
    element: () => <Navigate to={PublicPaths.landing} />
  },
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyTermsPage,
    index: false,
    private: false,
    element: Terms,
  },
  {
    id: PublicPaths.policyTermsPage,
    path: PublicPaths.policyPrivacyPage,
    index: false,
    private: false,
    element: Privacy,
  },
  {
    id: PublicPaths.landing,
    path: PublicPaths.landing,
    title: TITLE,
    index: false,
    private: false,
    element: GatherlanceLanding,
    children: [
      {
        id: PublicPaths.freelance,
        path: PublicPaths.freelance,
        title: TITLE,
        private: false,
        element: GatherlanceLandingFreelanceView,
        outsideLayout: true,
      },
      {
        id: PublicPaths.employee,
        path: PublicPaths.employee,
        title: TITLE,
        private: false,
        element: GatherlanceLandingEmployeeView,
        outsideLayout: true,
      },
    ],
  },
];

export const iziRoute = new IziRoute({
  routes,
});
