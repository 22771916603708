export const PublicPaths = {
  indexPage: "",
  projectPage: "/project",
  landingPage: "/project",
  publishedProjects: "/published-projects",
  publishedProjectDetail: "/published-projects/:projectId",
  product: "/product",
  productDetail: "/product/:projectId",
  policyTermsPage: "/policy/terms",
  policyPrivacyPage: "/policy/privacy",
  gatherlance: "/gatherlance",
  freelance: "freelance",
  employee: "employee",
  landing: "landing",
} as const;
